import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import '../../../assets/css/styles.css'
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import './medals.css'
import React, {useEffect, useState} from "react";
import axiosInstance from "../../../assets/utils/api";
import gold from '../../../assets/images/medals/gold.png';
import silver from '../../../assets/images/medals/silver.png';
import bronze from '../../../assets/images/medals/bronze.png';
import total from '../../../assets/images/medals/total.png';
import {Add, Remove} from "@mui/icons-material";

const Medals = () => {
    const [data, setData] = useState([
        {
            game: "Archery",
            medals: {
                gold: 39,
                silver: 41,
                bronze: 33,
                total: 113
            },
            winners: [
                {
                    name: "Rohit",
                    medal: "Silver"
                },
                {
                    name: "Sanjiv",
                    medal: "Gold"
                }
            ]
        }, {
            game: "Archery",
            medals: {
                gold: 39,
                silver: 41,
                bronze: 33,
                total: 113
            },
            winners: [
                {
                    name: "Rohit",
                    medal: "Silver"
                },
                {
                    name: "Sanjiv",
                    medal: "Gold"
                }
            ]
        }, {
            game: "Archery",
            medals: {
                gold: 39,
                silver: 41,
                bronze: 33,
                total: 113
            },
            winners: [
                {
                    name: "Rohit",
                    medal: "Silver"
                },
                {
                    name: "Sanjiv",
                    medal: "Gold"
                }
            ]
        }, {
            game: "Archery",
            medals: {
                gold: 39,
                silver: 41,
                bronze: 33,
                total: 113
            },
            winners: [
                {
                    name: "Rohit",
                    medal: "Silver"
                },
                {
                    name: "Sanjiv",
                    medal: "Gold"
                }
            ]
        }, {
            game: "Archery",
            medals: {
                gold: 39,
                silver: 41,
                bronze: 33,
                total: 113
            },
            winners: [
                {
                    name: "Rohit",
                    medal: "Silver"
                },
                {
                    name: "Sanjiv",
                    medal: "Gold"
                }
            ]
        }, {
            game: "Archery",
            medals: {
                gold: 39,
                silver: 41,
                bronze: 33,
                total: 113
            },
            winners: [
                {
                    name: "Rohit",
                    medal: "Silver"
                },
                {
                    name: "Sanjiv",
                    medal: "Gold"
                }
            ]
        },
    ]);

    useEffect(() => {
        const fetchMedals = async () => {
            try {
                const response = await axiosInstance.get('/api/medals', {
                    headers: {
                        'pagename': 'Home',
                        'access-token': 'PpoPuGp2@12Notifcation',
                        'eventid': 1
                    }
                })
                // setData(response.data[0])
            } catch (e) {
                console.error(e)
            }
        }
        // fetchMedals()
    }, []);

    function Row({row, index}) {
        const [open, setOpen] = useState(false);

        return (
            <React.Fragment key={index}>
                <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>{row?.game || 'N/A'}</TableCell>
                    <TableCell>{row?.medals?.gold || 'N/A'}</TableCell>
                    <TableCell>{row?.medals?.silver || 'N/A'}</TableCell>
                    <TableCell>{row?.medals?.bronze || 'N/A'}</TableCell>
                    <TableCell>{row?.medals?.total || 'N/A'}</TableCell>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <Remove/> : <Add/>}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow sx={{backgroundColor: '#eeeeee'}}>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <table className='collapse-table'>
                                <tr>
                                    <th>S.no.</th>
                                    <th>Winner Name</th>
                                    <th>Medal</th>
                                </tr>
                                {
                                    row?.winners?.map((winner, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{winner.name}</td>
                                            <td>{<img
                                                src={winner.medal === 'Gold' ? gold : winner.medal === 'Silver' ? silver : bronze}
                                                alt='Gold medal' height='20px'
                                                className='me-1'/>} {winner.medal}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <>
            <InnerBanner pageName="Medals"/>
            <section className='section container'>
                <div className="inner-container-small text-start my-3 my-md-5">
                    <h2 className="text-center mb-12 mb-lg-5 lower-text">Medals Table</h2>
                    <h1 className="large-text text-center upper-text">Medals Table</h1>
                </div>

                <TableContainer className='medal-table-container'>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>S.no</TableCell>
                                <TableCell>Game</TableCell>
                                <TableCell><img src={gold} alt='Gold medal' height='20px'
                                                className='me-1'/>Gold</TableCell>
                                <TableCell><img src={silver} alt='Gold medal' height='20px'
                                                className='me-1'/>Silver</TableCell>
                                <TableCell><img src={bronze} alt='Gold medal' height='20px'
                                                className='me-1'/>Bronze</TableCell>
                                <TableCell><img src={total} alt='Gold medal' height='20px'
                                                className='me-1'/>Total</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data?.length === 0 ? (
                                        <p>No Record found</p>
                                    ) :
                                    (data?.map((row, index) => (
                                        <Row row={row} index={index}/>
                                    )))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        </>
    )
}
export default Medals;