import axiosInstance from '../../assets/utils/api';

const eventRelatedNewsApi = async (eventType, eventID) => {
    try {
        const response = await axiosInstance.get(`api/EventsLinkedNews`,
            {
                headers: {
                    'access-token': 'VentthoeCr3*PrvROz_9roUp',
                    'eventtype': eventType,
                    'eventid': eventID
                }
            });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default eventRelatedNewsApi;