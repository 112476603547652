import React, { useEffect, useState } from 'react'
import getWidgets from '../../Apis/widgetApi';


function Widgets() {

    const [facebook, setFacebook] = useState({});
    const [instagram, setInstagram] = useState({});

    useEffect(() => {
        const fetchWidget = async () => {
            try {
                const response = await getWidgets('Facebook');
                setFacebook(response?.widgets[0])
                const response2 = await getWidgets('Instagram');
                setInstagram(response2?.widgets[0])
            } catch (e) {
                console.error('Error: ', e);
            }
        }
        fetchWidget()
    }, [])


    return (
        <>{facebook?.widget !== '' &&
            <section className="section">
                <div className="container">
                    <div className="inner-container-small text-start mb-3 mb-md-5">
                        <h2 className="text-center mb-3 mb-lg-5 ">
                            Founder Desk</h2>
                        <h1 className="large-text text-center ">Founder Desk</h1>
                    </div>
                    <div dangerouslySetInnerHTML={{__html:facebook?.widget}}></div>
                    {/* <iframe
                        src="https://snapwidget.com/embed/1087089"
                        class="snapwidget-widget"
                        allowtransparency="true"
                        frameborder="0"
                        scrolling="no"
                        style={{ border: 'none', overflow: 'hidden', width: '100%' }}
                        title="Posts from Facebook" /> */}
                </div>
            </section >
        }{
                instagram?.widget !== '' &&
                <section className="section">
                    <div className="container">
                        <div className="inner-container-small text-start mb-3 mb-md-5">
                            <h2 className="text-center mb-3 mb-lg-5 ">Pythian Games Instagram</h2>
                            <h1 className="large-text text-center ">Pythian Games Instagram</h1>
                        </div>
                        <div dangerouslySetInnerHTML={{__html:instagram?.widget}}></div>
                        {/* <iframe
                        src="https://snapwidget.com/embed/1082162\"
                        className="snapwidget-widget"
                        allowTransparency="true"
                        frameBorder="0"
                        scrolling="no"
                        style={{ border: 'none', overflow: 'hidden', width: '100%' }}
                        title="Posts from Instagram" /> */}
                    </div>
                </section>
            }
        </>
    )
}

export default Widgets