import React, { useEffect, useState } from 'react'
import eventsApi from '../../Apis/eventsApi';
import tabImg1 from '../../../assets/images/tab-img-1.jpg'
import eventRelatedNewsApi from '../../Apis/eventRelatedNews'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, IconButton, Modal } from '@mui/material';
import { Close, NavigateBefore, NavigateNext, PlayCircle } from '@mui/icons-material';

function PreviousEvents() {
    const [eventData, setEventData] = useState([]);
    const [heading, setHeading] = useState('');
    const [activeTab, setActiveTab] = useState(0)
    const [tabData, setTabData] = useState({});
    const [modal, setModal] = useState({ open: false, src: '', type: 'youtube' });
    const [eventVideoData, setEventVideo] = useState({
        data: [],
        title: "",
        current: 0,
    });
    const currentVideo = eventVideoData.data[eventVideoData.current] || {};

    const handleNext = () => {
        setEventVideo((prev) => ({
            ...prev,
            current: (prev.current + 1) % prev.data.length,
        }));
    };
    const handlePrev = () => {
        setEventVideo((prev) => ({
            ...prev,
            current: prev.current > 0 ? prev.current - 1 : prev.data.length - 1,
        }));
    };

    const fetchEventDetail = async (id) => {
        try {
            const res = await eventRelatedNewsApi('previous', id);
            if (res?.data) {

                setEventVideo({
                    data: res?.data,
                    title: res?.headingText,
                    current: 0,
                });
            } else {

                setEventVideo({
                    data: [],
                    title: "",
                    current: 0,
                })
            }
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    const handleCloseModal = () => {
        setModal({ open: false, src: '', isIframe: '' })
    }
    useEffect(() => {
        fetchEventDetail(eventData[activeTab]?.eventaid)
    }, [activeTab])

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNext();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [eventVideoData.data]);

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const res = await eventsApi('previous');
                setEventData(res)
                fetchEventDetail(res[0]?.eventaid);
            } catch (e) {
                console.error('Error: ', e);
            }
        }
        fetchEvent();
    }, [])


    return (
        // <div>PreviousEvents</div>
        <div className="container">
            <Modal
                open={modal.open}
                onClose={() => handleCloseModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box id='event-video-box'>
                    <div style={{ position: 'relative' }}>
                        {
                            modal.type === 'youtube' ?
                                <iframe src={modal.src} className="iframe d-block w-100" frameBorder="0"
                                    style={{ aspectRatio: '4 / 3' }}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen="true"></iframe>
                                : modal.type === 'video' ? <video autoPlay='1' controls style={{ width: '100%' }}>
                                    <source src={modal.src} />
                                </video> : modal.type === 'image' ?
                                    <img src={modal.src} width='100%' alt='event image' /> : ''
                        }
                        <Close className='close-icon' onClick={() => handleCloseModal()} titleAccess='Close Video' />
                    </div>
                </Box>
            </Modal>
            <div className="inner-container-small text-start mb-3 mb-md-5">
                <h2 className="text-center mb-12 mb-lg-5 lower-text">Previous Events</h2>
                <h1 className="large-text text-center upper-text">Previous Events</h1>
            </div>
            <div className="row">
                <div className=" col-12 col-sm-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                        aria-orientation="vertical">
                        {
                            eventData.map((item, index) => (
                                <button className={activeTab === index ? "navtab active" : "navtab"} id="v-pills1-tab" type="button" onClick={() => setActiveTab(index)}>
                                    <span className="number_div">{index + 1}</span>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                        <p className='m-0' style={{ color: activeTab === index ? 'white' : '', fontSize: '16px' }}>{item?.eventname}</p>
                                        <p className='m-0' style={{ display: 'flex', alignItems: 'center', gap: '5px', fontWeight: '400', color: activeTab === index ? 'white' : '' }}>{<DateRangeIcon />}{' '}{item?.startdate}{' to '}{item?.enddate}</p>
                                        <p className='m-0' style={{ display: 'flex', alignItems: 'center', gap: '5px', fontWeight: '400', textAlign: 'left', lineHeight: '18px', color: activeTab === index ? 'white' : '' }}>{<LocationOnIcon />}{' '}{item?.combinedadd}</p>
                                    </div>
                                </button>
                            ))
                        }
                    </div>
                </div>
                <div className="tab-content col-12 col-sm-12 col-md-12 col-lg-8 text-justify"
                    id="v-pills-tabContent">
                    <div className="w-100 p-1">
                        {
                            eventVideoData?.data.length > 0 ?
                                <div className="min-box-height box-shadow card-shadow">
                                    <h5 className="mb-3" style={{ textAlign: 'left' }}>{eventVideoData?.title}</h5>
                                    <div style={{ position: "relative" }}>
                                        <div>
                                            <img
                                                src={currentVideo?.thumbnail || "/default-thumbnail.jpg"}
                                                width="100%"
                                                alt={currentVideo?.alt || "Video thumbnail"}
                                                style={{ transition: '0.3s' }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                height: "100%",
                                                padding: "0 20px",
                                            }}
                                        >
                                            <IconButton
                                                sx={{ padding: "0!important", backgroundColor: "white" }}
                                                onClick={handlePrev}
                                                disabled={eventVideoData?.current === 0}
                                            >
                                                <NavigateBefore sx={{ fontSize: "32px" }} />
                                            </IconButton>

                                            <IconButton
                                                sx={{ padding: "0!important" }}
                                                onClick={() =>
                                                    setModal({
                                                        open: true,
                                                        src: currentVideo?.eventimage,
                                                        type: currentVideo?.type,
                                                    })
                                                }
                                            >
                                                <PlayCircle sx={{ fontSize: "50px", color: "white" }} />
                                            </IconButton>

                                            <IconButton
                                                sx={{ padding: "0!important", backgroundColor: "white" }}
                                                onClick={handleNext}
                                                disabled={eventVideoData?.current === eventVideoData?.data.length - 1}
                                            >
                                                <NavigateNext sx={{ fontSize: "32px" }} />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 style={{ fontSize: "18px", fontWeight: 500, marginTop: "10px" }}>
                                            {currentVideo?.headingtext || "Default Heading"}
                                        </h4>

                                        <p>{currentVideo?.headingtextdescription || "No description available"}</p>
                                        <p>
                                            {currentVideo?.source && `Source: ${currentVideo?.source}`}
                                        </p>
                                    </div>
                                </div>
                                : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviousEvents