import axiosInstance from '../../assets/utils/api';

const getWidgets = async (title) => {
    try {
        const response = await axiosInstance.get(
            `/api/SocialMediaWidget`, {
            headers: {
                'Access-Token': 'sp=druyesuPre1+f521f',
                'title':title
            }
        }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default getWidgets;