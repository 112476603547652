import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import './Founder.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import FounderImage from '../../../assets/images/founder.png'
import { Facebook } from "@mui/icons-material";

const Founder = () => {
    return (<div>
        <InnerBanner pageName={"Founder's Message"} />

        <section className="founder section">
            <div className="container">
                <div className="founder-main">
                    <div className="picture me-3">
                        <img src={FounderImage} />
                        <div className="founder-info">
                            <h3 className="mb-0 text-center">Bijender Goel</h3>
                            <p className="founder-info text-center">Founder,
                                Modern Pythian Games <br />
                            </p>
                            <a
                                // onClick={() => window.location.href = 'https://www.facebook.com/share/18bqPuVZwF/?mibextid=wwXIfr'}
                                href="https://www.facebook.com/share/18bqPuVZwF/?mibextid=wwXIfr"
                                target="_blank"
                                style={{
                                    backgroundColor: 'white',
                                    padding: '5px',
                                    borderRadius: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    margin: '0 20px 8px 20px',
                                    color: 'black',
                                    justifyContent: 'center'
                                }}>
                                <Facebook sx={{ color: '#1877F2' }} />
                                Facebook
                            </a>
                        </div>
                    </div>

                    <div className="founder-text text-justify">
                        <p><b>Dear Participants, Supporters, and Friends,</b></p>
                        <p>Namaste!</p>
                        <p>The Modern Pythian Games are more than just an event—they represent a global celebration of
                            unity through arts, culture, and traditional games. Inspired by the ancient Pythian Games of
                            Greece, we have revived this legacy after 1,630 years, providing artists and athletes a
                            unique platform to showcase their talents.

                        </p>
                        <p>Our mission is to bring people and nations together, fostering peace and understanding
                            through creativity and tradition. Unlike the Olympics, which focus solely on sports, the
                            Pythian Games celebrate the richness of culture and heritage, offering opportunities for
                            everyone—from students to professionals—to participate and shine on a global stage.
                        </p>
                        <p>The success of the 1st National Cultural Pythian Games, with the participation of more than
                            5,000 artists and players from diverse fields and categories, is a testament to this shared
                            vision. I extend my heartfelt gratitude to all participants, partners, and supporters who
                            made this event possible. Together, we are creating history and paving the way for a
                            brighter future.
                        </p>
                        <p>Looking ahead, we aim to host the 1st Pythian Games in 2027 in Athens, Greece, the Para
                            Pythian Games in 2025, and the Youth Pythian Games in 2026. These events will further our
                            mission of uniting the world through arts, culture, and traditional games.
                        </p>
                        <p>Join us in this remarkable journey. Let’s preserve our heritage, celebrate creativity, and
                            inspire generations to come.
                        </p>
                        <p>With warm regards,<br />
                            <b>Bijender Goel</b><br />
                            Founder, Modern Pythian Games
                        </p>

                    </div>
                </div>
            </div>
        </section>
    </div>)
}
export default Founder