import axiosInstance from '../../assets/utils/api';

const getButtonDetails = async (slug) => {
    try {
        const response = await axiosInstance.get(`api/LoginSignButton`,
            {
                headers: {
                    'access-token': 'onigL/#ngUpiS@bkkb21@!2^5', 
                    'slug': slug
                }
            });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default getButtonDetails;