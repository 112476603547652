import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import './History.css'
import aboutImg3 from '../../../assets/images/about-img3.png'
import tabImg1 from '../../../assets/images/tab-img-1.jpg'
import tabImg2 from '../../../assets/images/tab-img-2.jpg'
import tabImg3 from '../../../assets/images/tab-img-3.jpg'
import tabImg4 from '../../../assets/images/tab-img-4.jpg'
import tabImg5 from '../../../assets/images/tab-img-5.jpg'
import tabImg6 from '../../../assets/images/tab-img-6.jpg'
import tabImg7 from '../../../assets/images/tab-img-7.jpg'
import {useEffect, useState} from "react";

const History = () => {

    const [tabNum, setTabNum] = useState(1)
    const [selectedContent, setSelectedContent] = useState(<></>)
    const renderContent = () => {
        switch (tabNum) {
            case 2:
                setSelectedContent(
                    <div className="tab-pane fade show active" id="v-pills2" role="tabpanel"
                         aria-labelledby="v-pills2-tab">
                        <div className="history-content  text-justify">
                            <figure><img className="tab-content-img"
                                         src={tabImg2} alt=""/>
                            </figure>
                            <h3>Pythian Games in ancient Greece</h3>
                            <p>The Pythian Games in ancient Greece were established in honour of Apollo, the god
                                of music,
                                poetry, prophecy, and healing. The story behind the Pythian Games dates back to
                                Greek
                                mythology and the myth of Apollo's slaying of the Python serpent.</p>
                            <p>According to legend, Python, a monstrous serpent, guarded the sacred site of
                                Delphi, believed
                                by the ancient Greeks to be the centre of the world. When Apollo was just a
                                young god, he
                                travelled to Delphi and encountered Python. In a fierce battle, Apollo defeated
                                Python and
                                claimed the sanctuary of Delphi as his own.</p>
                            <p>To commemorate his victory over Python and to purify himself of the crime, Apollo
                                established
                                the Pythian Games. These games were held every four years and included athletic
                                competitions, musical contests, and other artistic events. The Pythian Games
                                were one of the
                                four Panhellenic Games of ancient Greece, along with the Olympic, Nemean, and
                                Isthmian
                                Games.</p>
                            <p>The Pythian Games became a significant event in ancient Greek culture, drawing
                                athletes,
                                musicians, and artists from all across the Greek world to compete and showcase
                                their
                                talents. The winners of the Pythian Games were highly revered and often
                                celebrated as heroes
                                in their respective cities.</p>
                            <p>The Pythian Games continued for centuries, enduring various political and social
                                changes in
                                ancient Greece. They remained an integral part of Greek religious and cultural
                                life until
                                the decline of the ancient world.</p>
                            <p>Overall, the Pythian Games were born out of the mythological tale of Apollo's
                                triumph over
                                the Python serpent, symbolizing the victory of light over darkness and the
                                establishment of
                                Delphi as a sacred sanctuary dedicated to the god Apollo."</p>
                        </div>
                    </div>
                )
                break;
            case 3:
                setSelectedContent(
                    <div className="tab-pane fade show active" id="v-pills3" role="tabpanel"
                         aria-labelledby="v-pills3-tab">
                        <div className="history-content">
                            <figure><img className="tab-content-img"
                                         src={tabImg3} alt=""/>
                            </figure>
                            <h3>Early History of Delphi</h3>
                            <p>During the early Archaic period in the 8th century B.C., the Delphi sanctuary
                                served as the
                                focal point for the Amphictyony league, comprising 12 ancient tribes. This
                                league oversaw
                                the sanctuary's finances, operations, and appointments of priests and
                                officials.</p>
                            <p>Around 590 B.C., the Crissa community thrived due to trade and traffic to Delphi
                                but acted
                                immorally toward the sanctuary and its visitors. Details of their transgressions
                                remain
                                unclear, but historical evidence suggests they may have captured the oracle and
                                defiled the
                                temple.</p>
                            <p>In response, the league initiated the First Sacred War, which lasted
                                approximately a decade
                                and culminated in the destruction of the Crissa community. However, Delphi was
                                recognized as
                                an autonomous state, ensuring free access to the sanctuary and the reinstatement
                                of the
                                Pythian Games, held every four years since 582 B.C.</p>
                        </div>
                    </div>
                )
                break;
            case 4:
                setSelectedContent(
                    <div className="tab-pane fade show active" id="v-pills4" role="tabpanel"
                         aria-labelledby="v-pills4-tab">
                        <div className="history-content">
                            <figure><img className="tab-content-img"
                                         src={tabImg4} alt=""/>
                            </figure>
                            <h3>Who Built Delphi?</h3>
                            <p>It is believed that priests from Knossos brought the cult of Apollo to Delphi and
                                began
                                developing the sanctuary in the 8th century B.C. They erected the first stone
                                temples of
                                Apollo and Athena by the end of the 7th century B.C. Delphi's historical
                                significance
                                stretches back to Mycenaean times (1600–1100 B.C.), with evidence of a Mycenaean
                                settlement
                                within the sanctuary area. Additionally, archaeological findings suggest the
                                existence of a
                                sanctuary dedicated to the deity Gaia or Athena around 1400 B.C., possibly
                                destroyed by a
                                rockfall at the end of the Bronze Age. Artifacts from the Neolithic period (4000
                                B.C.), as
                                well as evidence of rituals in the Corycian Cave, have also been discovered on
                                the slopes of
                                Mount Parnassus.</p>
                            <p>Excavations confirm Delphi's existence in the 15th century BCE, with initial
                                habitation
                                occurring in late Mycenaean times. The Romans seized Delphi in the early 2nd
                                century BCE,
                                with Nero removing approximately 500 statues from the vicinity. Delphi's
                                significance waned
                                with the ascent of Christianity, eventually being buried beneath the site of a
                                new village
                                until the late 1800s. Designated as a UNESCO World Heritage Site in 1987,
                                Delphi, like other
                                religious regions in Greece, hosted games in honour of the Greek gods. The
                                Pythian Games,
                                held every four years and a year before the Olympics, ranked second only to the
                                Olympics in
                                Greek history.</p>
                        </div>
                    </div>
                )
                break;
            case 5:
                setSelectedContent(
                    <div className="tab-pane fade show active" id="v-pills5" role="tabpanel"
                         aria-labelledby="v-pills5-tab">
                        <div className="history-content">
                            <figure><img className="tab-content-img"
                                         src={tabImg5} alt=""/>
                            </figure>
                            <h3>Oracle of Delphi and Pythia</h3>
                            <p>The Oracle of Delphi enjoyed peak reverence between the 6th and 4th centuries
                                B.C. Rulers and
                                communities
                                sought counsel from the Pythia, who operated on limited days over nine months,
                                endowing
                                Delphi with
                                considerable influence. Pilgrims expressed gratitude by offering lavish gifts
                                and consulted
                                the Oracle on a
                                wide array of matters, including private and state affairs. The Pythia
                                purportedly sat on a
                                tripod chair
                                behind a curtain, inhaling light hydrocarbon gases emitted from a chasm,
                                entering a
                                trance-like state to
                                deliver prophetic utterances. These utterances were translated by priests,
                                believed to
                                accurately predict
                                historical events since time immemorial.</p>
                        </div>
                    </div>
                )
                break;
            case 6:
                setSelectedContent(
                    <div className="tab-pane fade show active" id="v-pills6" role="tabpanel"
                         aria-labelledby="v-pills6-tab">
                        <div className="history-content">
                            <figure><img className="tab-content-img"
                                         src={tabImg6} alt=""/>
                            </figure>
                            <h3>The Demise of Delphi</h3>
                            <p>Delphi's priests wielded significant political and military influence, guiding
                                the course of
                                affairs through their oracle. Multiple calamities, including the destruction of
                                the first
                                temple by fire in 548 B.C., reshaped Delphi's authority. Wars erupted in the 5th
                                and 4th
                                centuries over the oracle's pronouncements. Successive rulers, including the
                                Phocians and
                                Macedonians, exerted control over Delphi until the Aetolians conquered it in the
                                3rd century
                                B.C. Roman dominance began in 191 B.C., with Delphi retaining cultural
                                importance for some
                                Roman emperors. However, the Byzantine emperor Theodosius outlawed ancient
                                religions and
                                Pan-Hellenic games, leading to the oracle's demise in A.D. 393 or 394, and the
                                subsequent
                                destruction of statues and temples in Delphi.</p>
                        </div>
                    </div>
                )
                break;
            case 7:
                setSelectedContent(
                    <div className="tab-pane fade show active" id="v-pills7" role="tabpanel"
                         aria-labelledby="v-pills7-tab">
                        <div className="history-content">
                            <figure><img className="tab-content-img"
                                         src={tabImg7} alt=""/>
                            </figure>
                            <h3>Revival of Pythian Games - Modern Pythian Games</h3>
                            <p>Mr. Bijender Goel, the visionary founder, spearheaded the revival of the Pythian
                                Games in the 21st century from New Delhi. His goal was to resurrect the essence
                                of these ancient games, which celebrated art, culture, and sports, and bring
                                them into the modern era. The idea behind the Modern Pythian Games was to
                                leverage technology and human power to provide artists and players worldwide
                                with ample opportunities for participation and recognition. Mrs Meenakshi Lekhi,
                                Honourable Minister of state for external affairs and Department of Culture,
                                government of India, officially announced the revival of Pythian Games as modern
                                Pythian Games on 19th December 2023 at first Pythian games Festival at Thyagraj
                                Stadium, New Delhi, India.</p>
                        </div>
                    </div>
                )
                break;
            default:
                setSelectedContent(
                    <div className="tab-pane fade show active" id="v-pills1" role="tabpanel"
                         aria-labelledby="v-pills1-tab">
                        <div className="history-content">
                            <figure><img className="tab-content-img"
                                         src={tabImg1} alt=""/>
                            </figure>
                            <h3>Delphi, Greece – the city of God Apollo</h3>
                            <p>Delphi served as a Pan-Hellenic sanctuary for all Greek cities and states.
                                Designated as a
                                UNESCO World Heritage Site, Delphi derives its name from "Dolphin," as ancient
                                beliefs held
                                that Lord Apollo first visited the region in the form of a dolphin. Ancient
                                Greeks
                                considered Delphi the centre of the earth, formerly known as Python. The region
                                housed the
                                magnificent Sanctuary of Apollo, a significant centre in ancient Greece. It was
                                believed
                                that priests and officials consulted the renowned oracle Pythia for insights
                                into their
                                futures. The Omphalos, a stone monument marking the centre of the world, was a
                                testament to
                                the ancient Greeks' beliefs. Greek mythology recounts how Zeus, seeking the
                                centre of
                                "Grandmother Earth" (Gaia), marked Delphi by sending eagles from the west and
                                east,
                                converging over the site. Delphi became associated with Apollo after he slew a
                                python named
                                "Delphyne" or "Drakiana" and became its guardian.</p>
                            <p>Situated approximately six miles from the Gulf of Corinth in Phocis, Greece,
                                Delphi nestles
                                between towering rocks of Mount Parnassus, also known as the Shining Rocks. The
                                site
                                encompasses the Sanctuary of Athena Pronaia, honouring the virgin goddess of
                                practical
                                skills and wisdom. Additionally, several buildings, including the gymnasium for
                                physical
                                exercise and education, dot the area.</p>
                            <p>A path known as the Sacred Way leads to the Sanctuary of Apollo, culminating in a
                                theatre
                                overlooking the temple. Adjacent to the Temple of Apollo lies the stadium,
                                surrounded by
                                treasuries and holy monuments. These treasuries, erected as offerings to Apollo,
                                showcased
                                the affluence and influence of various cities. In the 4th century, a limestone
                                theatre with
                                35 rows, accommodating 5000 spectators, was constructed and continues to host
                                events today.
                            </p>
                        </div>
                    </div>
                )
        }
    }

    useEffect(() => {
        renderContent()
    }, [renderContent, tabNum]);
    return (
        <>
            <InnerBanner pageName='History'/>
            <section className="our-history section">
                <div className="container">
                    <div className="row mb-3 mb-md-5">
                        <div className="col col-sm-12 col-md-12 col-lg-6">
                            <img className="w-100" src={aboutImg3}/>

                        </div>
                        <div className="col col-sm-12 col-md-12 col-lg-6 align-content-center text-justify">
                            <h2 className="mb-3 lh-base titleFont title">PAN HELLENIC GAMES</h2>
                            <p>Panhellenic Games is the collective term for four separate sports festivals held in
                                ancient Greece. The four Games were:</p>
                            <table className='history-table'>
                                <tr>
                                    <th>Games</th>
                                    <th>Deity Honored</th>
                                    <th>Location</th>
                                    <th>Prize</th>
                                    <th>Frequency</th>
                                </tr>
                                <tr>
                                    <td>Olympic Games</td>
                                    <td>Zeus</td>
                                    <td>Olympia, Elis</td>
                                    <td>Olive wreath (Kotinos)</td>
                                    <td>Every 4 years (marked the start of year 1 of an Olympiad)</td>
                                </tr>
                                <tr>
                                    <td>Pythian Games</td>
                                    <td>Apollo</td>
                                    <td>Delphi</td>
                                    <td>Laurel wreath</td>
                                    <td>Every 4 years (2 years after the Olympic Games; Olympiad year 3)</td>
                                </tr>
                                <tr>
                                    <td>Nemean Games</td>
                                    <td>Zeus<br/>Heracles</td>
                                    <td>Nemea, Corinthia</td>
                                    <td>Wild celery</td>
                                    <td>Every 2 years (year before and after the Olympic Games; Olympiad years 2 and
                                        4)
                                    </td>
                                </tr>
                                <tr>
                                    <td>Isthmian Games</td>
                                    <td>Poseidon</td>
                                    <td>Isthmia, Sicyon</td>
                                    <td>Pine</td>
                                    <td>Every 2 years (same year as the Nemean Games, different time of year)</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-justify">
                            <h2 className="mb-3 lh-base titleFont title text-center">Our History</h2>

                            <p>In ancient times, Delphi was considered the center of the world. It was there that, for
                                almost 1000 years, the Pythian Games took place, a peaceful competition of the arts.
                                Even though Delphi isn't as prominent as it used to be, the fact that it was named a
                                World Cultural Heritage Site by UNESCO in 1987, is a big recognition to the European
                                culture as a whole.</p>
                            <p>The Pythian Games were one of the four Panhellenic Games of Ancient Greece. They were
                                held in honor of Apollo at his sanctuary at Delphi every four years, two years after the
                                Olympic Games and between each of the Nemean and Isthmian Games. The Pythian Games were
                                founded sometime in the 6th century BC. In legend, they were started by Apollo after he
                                killed Python and set up the oracle at Delphi.</p>
                            <p>The historical timeframe of the Pythian Games started in 582 BC, when the administration
                                of the Games was handed over to the Delphic Amphictyony, a council of twelve Greek
                                tribes, at the end of the First Sacred War. As of that time, they did not take place
                                every eight years as in the past, but every four years, two years before and after the
                                Olympic Games, presumably at the end of August. The Games lasted for six to eight days
                                and were started by a reenactment of the victory of Apollo over Python. A ritual
                                sacrifice was performed in the Temple of Apollo in a festive and glamorous procession.
                                After four days of festivities, the games began.</p>
                            <p>
                                During the Pythian Games, the little village Delphi in Greece was a place for the peace,
                                brotherhood and excellence in the sphere of arts, culture and sports. People travelled
                                to Delphi to seek fame and glory and showcase their skills. The games shaped European
                                history in a significant and sustainable way.


                            </p>
                            <p>The Pythian Games were ranked second in importance behind the Olympics. Unlike the
                                Olympics, the Pythian Games also featured competitions for art and dance, which predated
                                the athletic portion of the games, and women were allowed to take part in some events.
                                Victors received a wreath of bay laurel, sacred to Apollo, from the city of Tempe, in
                                Thessaly. Smaller versions of the Pythian Games were celebrated in many other cities of
                                the Levant and Greece. Unfortunately, the testimonials and documents covering the
                                Pythian Games were mainly destroyed through human violence and natural catastrophes. All
                                the remaining resources highlight the glory and glamour of the Games. The Pythian Games
                                were discontinued in 394 AD and have not been revived since.

                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Ancient-History mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 py-3">
                            <div>
                                <h2 className="mb-3 lh-base text-center titleFont title">Ancient History of Pythian
                                    Games</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className=" col-12 col-sm-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                                 aria-orientation="vertical">
                                <button className={tabNum === 1 ? "navtab active" : "navtab"}
                                        onClick={() => setTabNum(1)} id="v-pills1-tab"
                                        type="button">
                                    <span className="number_div">1</span>
                                    Delphi, Greece – the city
                                    of God Apollo
                                </button>
                                <button className={tabNum === 2 ? "navtab active" : "navtab"}
                                        onClick={() => setTabNum(2)} id="v-pills2-tab"><span
                                    className="number_div">2</span> Pythian Games in ancient
                                    Greece
                                </button>
                                <button className={tabNum === 3 ? "navtab active" : "navtab"}
                                        onClick={() => setTabNum(3)} id="v-pills3-tab">
                                    <span className="number_div">3</span>
                                    Early History of
                                    Delphi
                                </button>
                                <button className={tabNum === 4 ? "navtab active" : "navtab"}
                                        onClick={() => setTabNum(4)} id="v-pills4-tab">
                                    <span className="number_div">4</span>
                                    Who Built
                                    Delphi?
                                </button>
                                <button className={tabNum === 5 ? "navtab active" : "navtab"}
                                        onClick={() => setTabNum(5)} id="v-pills5-tab">
                                    <span className="number_div">5</span>
                                    Oracle of Delphi and
                                    Pythia
                                </button>
                                <button className={tabNum === 6 ? "navtab active" : "navtab"}
                                        onClick={() => setTabNum(6)} id="v-pills6-tab">
                                    <span className="number_div">6</span>
                                    The Demise of
                                    Delphi
                                </button>
                                <button className={tabNum === 7 ? "navtab active" : "navtab"}
                                        onClick={() => setTabNum(7)} id="v-pills7-tab">
                                    <span className="number_div">7</span>
                                    Revival of Pythian Games
                                    - Modern Pythian Games
                                </button>
                            </div>
                        </div>
                        <div className="tab-content col-12 col-sm-12 col-md-12 col-lg-8 text-justify"
                             id="v-pills-tabContent">
                            {selectedContent}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default History